import { defineStore } from "pinia";

export const useMainStore = defineStore("main", {
  state: () => ({
    count: true,

    snackbar: {
      visible: false,
      text: "",
      color: "red",
      timeout: 6000,
      multiline: false,
      loader: {
        title: "",
        bool: false,
      },
    },
    toastConfig: {
      visible: false,
      message: "",
      toastType: "info",
      callback: null,
    },
    snackbarNew: {
      show: false,
      message: '',
      color: 'success',
    },
    isLoggedIn: false,
    loginToken: "",
    userDetails: {},
    registerDialog: false
  }),
  persist: {
    storage: persistedState.localStorage,
  },
  actions: {
    triggerSnackbar(message, color = 'success') {
      this.snackbarNew.message = message;
      this.snackbarNew.color = color;
      this.snackbarNew.show = true;

      setTimeout(() => {
        this.snackbarNew.show = false;
      }, 3000)
    },

    increment() {
      this.count++;
    },

    setToastConfig(config) {
      this.toastConfig = config;
      // console.log(this.toastConfig, "this.toastConfig");
    },

    SetToken(payload) {
      // console.log(payload, "this.SetToken payload");
      this.isLoggedIn = payload.isLoggedIn;
      this.loginToken = payload.loginToken;
      this.userDetails = payload.userDetails;
    },

    updateUserDetails(payload) {
      // console.log(payload, "payload")
      this.userDetails = payload;
    },

    resetStore() {
      // console.log("resetStore called")
      localStorage.removeItem('main')
      this.$reset()
    }
  },

  getters: {
    fetchToastConfig(state) {
      return state.toastConfig;
    },
  },
});
